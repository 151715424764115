import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

var aws = require("aws-sdk");

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const userPool = new CognitoUserPool(poolData);

export const createCognitoUser = (email) => {
  return new CognitoUser({ Username: email, Pool: userPool });
};

export const createAuthDetails = (email, password) => {
  var authenticationData = {
    Username: email,
    Password: password,
  };
  return new AuthenticationDetails(authenticationData);
};

const adminPoolData = {
  UserPoolId: process.env.REACT_APP_ADMIN_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_ADMIN_COGNITO_CLIENT_ID,
};

export const adminUserPool = new CognitoUserPool(adminPoolData);

export const createAdminCognitoUser = (email) => {
  return new CognitoUser({ Username: email, Pool: adminUserPool });
};

export const createAdminAuthDetails = (email, password) => {
  var authenticationData = {
    Username: email,
    Password: password,
  };
  return new AuthenticationDetails(authenticationData);
};

export const checkAdmin = (iss) => {
  var array = iss.split("/");
  var userPoolId = array[array.length - 1];

  if (userPoolId === process.env.REACT_APP_ADMIN_COGNITO_USER_POOL_ID) return true;
  else return false;
};
