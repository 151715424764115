import { GET_USERS, LOADING_STATUS } from "redux/actions/userActions";

const initState = {
  users: [],
  user: null,
  status: null,
};

const userReducer = (state = initState, action) => {
  if (action.type === GET_USERS) {
    return {
      ...state,
      users: action.payload,
    };
  }
  if (action.type === LOADING_STATUS) {
    return {
      ...state,
      status: action.payload,
    };
  }
  return state;
};

export default userReducer;
