import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useStore } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Loader from "components/Loader/Loader";

import { checkAdmin } from "helper/cognito";
import { MetaTags } from "react-meta-tags";

const Home = lazy(() => import("pages/Home"));
const Projects = lazy(() => import("pages/Projects"));
const ProjectDetail = lazy(() => import("pages/ProjectDetail"));
const About = lazy(() => import("pages/About"));
const Contact = lazy(() => import("pages/Contact"));
const Login = lazy(() => import("pages/Login"));
const Signup = lazy(() => import("pages/Signup"));
const Verify = lazy(() => import("pages/Verify"));
const AdminLogin = lazy(() => import("pages/AdminLogin"));
const Admin = lazy(() => import("layouts/Admin.js"));

const App = () => {
  const store = useStore();
  return (
    <>
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
user-scalable=0"
        ></meta>
      </MetaTags>
      <ToastProvider placement="top-right">
        <BreadcrumbsProvider>
          <Router>
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Home}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/projects/view/:projectId"}
                  component={ProjectDetail}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/projects"}
                  component={Projects}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/signup"}
                  component={Signup}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/verify"}
                  component={Verify}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={Login}
                />{" "}
                <Route
                  path={process.env.PUBLIC_URL + "/admin-login"}
                  component={AdminLogin}
                />
                {store.getState().authData.user &&
                  checkAdmin(store.getState().authData.user.iss) && (
                    <Route
                      path={process.env.PUBLIC_URL + "/admin"}
                      component={Admin}
                    />
                  )}
                <Route render={() => <Redirect to={{ pathname: "/" }} />} />
              </Switch>
            </Suspense>
          </Router>
        </BreadcrumbsProvider>
      </ToastProvider>
    </>
  );
};

export default App;
