import axios from "axios";

export const GET_USERS = "GET_USERS";
export const LOADING_STATUS = "LOADING_STATUS";

const token = localStorage.getItem("token");

export const getAllUsers = () => {
  return (dispatch, getState) => {
    axios
      .get(process.env.REACT_APP_API + "/users", {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(async (response) => {
        console.log("Response: ", response.data);
        const cleanedData = cleanData(response.data.Users);
        console.log("Cleaned Response: ", cleanedData);
        dispatch({
          type: GET_USERS,
            payload: cleanedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const cleanData = (data) => {
  return data.map((user) => {
    return {
      email: user.Attributes[1].Value,
      joinedDate: new Date(user.UserCreateDate).toString(),
      status: user.UserStatus,
    };
  });
};
