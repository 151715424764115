import {
  GET_PROJECTS,
  SET_PROJECT,
  LOADING_STATUS,
} from "redux/actions/projectActions";

const initState = {
  projects: [],
  project: null,
  status: null,
};

const projectReducer = (state = initState, action) => {
  if (action.type === GET_PROJECTS) {
    return {
      ...state,
      projects: action.payload,
    };
  }
  if (action.type === SET_PROJECT) {
    return {
      ...state,
      project: action.payload,
    };
  }
  if (action.type === LOADING_STATUS) {
    return {
      ...state,
      status: action.payload,
    };
  }
  return state;
};

export default projectReducer;
