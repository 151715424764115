export const response = [
  {
    projectId: "abc123",
    title: "Cheap Website For The Broke Student",
    description:
      "This very website you are in is perhaps the cheapest possible way to host a website.",
    thumbnail: "https://via.placeholder.com/150",
    publish: false,
    isDeleted: false,
    createdAt: new Date(2020, 4, 3, 3, 24, 0),
    updatedAt: new Date(2020, 4, 4, 12, 24, 0),
    guides: [
      {
        name: "Introduction",
        icons: "",
        content:
          "intro.md",
      },
      {
        name: "Host a static website",
        icons: "",
        content:
          "intro.md",
      },
      {
        name: "Manage users",
        icons: "",
        content:
          "intro.md",
      },
      {
        name: "Build a serverless backend",
        icons: "",
        content:
          "intro.md",
      },
      {
        name: "Deploy a RESTful API",
        icons: "",
        content:
          "intro.md",
      },
      {
        name: "Terminate Resources",
        icons: "",
        content:
          "intro.md",
      },
    ],
  },
];
