import { LOGIN, LOGOUT } from "redux/actions/authActions";

const initState = {
  user: null,
};

const authReducer = (state = initState, action) => {
  if (action.type === LOGIN) {
    return {
      ...state,
      user: action.payload,
    };
  }
  if (action.type === LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

export default authReducer;
