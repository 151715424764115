import axios from "axios";

var aws = require("aws-sdk");

aws.config.update({
  region: "ap-southeast-1",
  accessKeyId: process.env.REACT_APP_S3_FULL_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_FULL_SECRET_ACCESS_KEY,
});

const S3_GUIDES_BUCKET = process.env.REACT_APP_GUIDE_BUCKET;
const S3_PUBLIC_BUCKET = process.env.REACT_APP_PUBLIC_BUCKET;

export const getFileFromS3 = (fileUrl) => {
  return new Promise(async (resolve, reject) => {
    const s3 = new aws.S3();

    var projectId = fileUrl.split("/")[3];
    var fileName = fileUrl.split("/")[4];

    const s3Params = {
      Bucket: S3_GUIDES_BUCKET,
      Key: `${projectId}/${fileName}`,
      Expires: 500,
    };

    var url = await s3.getSignedUrl("getObject", s3Params);
    resolve(url);
  });
};

export const getGuide = (fileUrl, fileName) => {
  return new Promise(async (resolve, reject) => {
    const s3 = new aws.S3();

    var projectId = fileUrl.split("/")[3];

    const s3Params = {
      Bucket: S3_GUIDES_BUCKET,
      Key: `${projectId}/${fileName}`,
      Expires: 500,
    };

    await s3.getSignedUrl("getObject", s3Params, (err, data) => {
      if (err) {
        console.log(err);
        reject(err.message);
      }
      fetch(data)
        .then((response) => response.text())
        .then((text) => {
          resolve(text);
        });
    });
  });
};

export const uploadThumbnail = (file) => {
  return new Promise(async (resolve, reject) => {
    const s3 = new aws.S3();
    const s3Params = {
      Bucket: S3_PUBLIC_BUCKET,
      Key: `thumbnails/${file.name}`,
      ContentType: file.type,
      Expires: 500,
    };

    var signedUrl = await s3.getSignedUrl("putObject", s3Params);
    var options = {
      headers: {
        "Content-Type": file.type,
      },
    };

    await axios
      .put(signedUrl, file, options)
      .then((response) => {
        let fileUrl = response.config.url.split("?")[0];
        resolve(fileUrl);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err.message);
      });
  });
};

export const uploadGuides = (projectId, files) => {
  return new Promise(async (resolve, reject) => {
    // console.log("Project Id: ", projectId);
    // console.log("Files: ", files);
    const s3 = new aws.S3();
    var guides = [];

    await Promise.all(
      files.map(async (file) => {
        const s3Params = {
          Bucket: S3_GUIDES_BUCKET,
          Key: `${projectId}/${file.name}`,
          ContentType: file.type,
          Expires: 500,
        };

        var signedUrl = await s3.getSignedUrl("putObject", s3Params);
        var options = {
          headers: {
            "Content-Type": file.type,
          },
        };

        await axios
          .put(signedUrl, file, options)
          .then((response) => {
            // console.log("S3 Response: ", response);
            let fileUrl = response.config.url.split("?")[0];
            // console.log(fileUrl);
            var guide = {
              name: file.name,
              icon: "",
              content: fileUrl,
            };
            guides.push(guide);
          })
          .catch((err) => {
            console.log(err.message);
            // reject(err.message);
          });
      })
    );

    resolve(guides);
  });
};
