import React from "react";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import { Box } from "@material-ui/core";

const override = css`
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, 1000%);
  transform: translate(-50%, 100%);
`;

const description = {
  margin: "0",
  position: "absolute",
  top: "30%",
  left: "50%",
  fontSize: "1.5em",
  transform: "translate(-50%, 0%)",
};

const phrases = ["Puffing clouds...", "On cloud 9...", "Just floating...", "Weather forecast: Cloudy"];

const Loader = (props) => {
  return (
    <Box height="70vh" width="100vw">
      <div className="sweet-loading">
        <div style={description}>
          {phrases[Math.floor(Math.random() * phrases.length)]}
        </div>
        <SyncLoader css={override} size={30} color={"#aaaaaa"} />
      </div>
    </Box>
  );
};

export default Loader;
