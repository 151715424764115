import { response } from "data/projects";
import { uploadGuides } from "helper/s3";
import axios from "axios";

export const GET_PROJECTS = "GET_PROJECTS";
export const SET_PROJECT = "SET_PROJECT";
export const LOADING_STATUS = "LOADING_STATUS";

const token = localStorage.getItem("token");

export const getProjects = () => {
  return (dispatch, getState) => {
    axios
      .get(
        process.env.REACT_APP_API + "/cloudprojects",
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(async (response) => {
        // console.log("Response: ", response.data);
        dispatch({
          type: GET_PROJECTS,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({
      type: GET_PROJECTS,
      payload: response,
    });
  };
};

export const setProject = (projectId) => {
  return (dispatch, getState) => {
    var projects = getState().projectData.projects;
    if (projects === null) {
      // call individual project API
    }
    var project = projects.find((item) => item.projectId === projectId);
    dispatch({
      type: SET_PROJECT,
      payload: project,
    });
  };
};

export const getAllProjects = () => {
  return async (dispatch, getState) => {
    axios
      .get(
        process.env.REACT_APP_API + "/projects",
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log("Response: ", response.data);
        var cleanedData = cleanData(response.data);
        dispatch({
          type: GET_PROJECTS,
          payload: cleanedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createProject = (data) => {
  return async (dispatch, getState) => {
    // console.log(data);
    dispatch({
      type: LOADING_STATUS,
      payload: "Starting creation process...",
    });
    var projects = getState().projectData.projects;
    var projectId = makeid(10);
    // eslint-disable-next-line
    while (projects && projects.find((item) => item.projectId === projectId)) {
      projectId = makeid(10);
    }
    dispatch({
      type: LOADING_STATUS,
      payload: "Uploading stuff to S3...",
    });
    var _guides = data.guideFiles
      ? await uploadGuides(projectId, data.guideFiles)
      : [];
    data = {
      ...data,
      projectId: projectId,
      createdAt: new Date(),
      updatedAt: new Date(),
      guides: _guides,
    };
    delete data.guideFiles;
    console.log("Data: ", data);
    dispatch({
      type: LOADING_STATUS,
      payload: "Creating entry in DynamoDB...",
    });
    await axios
      .post(
        process.env.REACT_APP_API + "/projects",
        data,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log("Response: ", response);
        dispatch({
          type: LOADING_STATUS,
          payload: null,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(getAllProjects());
  };
};

export const updateProject = (data) => {
  return async (dispatch, getState) => {
    // console.log(data);
    var _guides = data.guideFiles
      ? await uploadGuides(data.projectId, data.guideFiles)
      : data.guides;
    var newData = {
      ...data,
      updatedAt: new Date(),
      guides: _guides,
    };
    delete data.guideFiles;
    await axios
      .post(
        process.env.REACT_APP_API + "/projects",
        newData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        // console.log("Updated Response: ", response);
        dispatch({
          type: LOADING_STATUS,
          payload: null,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(getAllProjects());
  };
};

const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const cleanData = (data) => {
  return data.map((item) => {
    return {
      ...item,
      publish: item.publish === "true" ? true : false,
      isDeleted: item.isDeleted === "true" ? true : false,
    };
  });
};
