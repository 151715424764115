export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const login = (data, redirect, admin) => {
  return (dispatch, getState) => {
    localStorage.setItem("token", data.getJwtToken());
    dispatch({
      type: LOGIN,
      payload: data.payload,
    });
    console.log("Logging In: ", admin)
    if (admin) {
      window.location = process.env.PUBLIC_URL + "/admin/dashboard";
    } else {
      redirect.push("/");
    }
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    localStorage.clear();
    dispatch({
      type: LOGOUT,
    });
    window.location = process.env.PUBLIC_URL + "/login";
  };
};  
