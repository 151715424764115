import authReducer from "./authReducer";
import projectReducer from "./projectReducer";
import { combineReducers } from "redux";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  authData: authReducer,
  projectData: projectReducer,
  userData: userReducer,
});

export default rootReducer;
